

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face{
  src: url(assets/fonts/RedHatDisplay-Regular.woff2) format(woff2);
  font-family: "Red-Hat-Display-Regular";
  font-display: swap;
}
@font-face{
  src: url(assets/fonts/RedHatDisplay-Light.woff2) format(woff2);
  font-family: "Red-Hat-Display-Light";
  font-display: swap;
}
@font-face{
  src: url(assets/fonts/RedHatDisplay-Medium.woff2) format(woff2);
  font-family: "Red-Hat-Display-Medium";
  font-display: swap;
}
@font-face{
  src: url(assets/fonts/RedHatDisplay-SemiBold.woff2) format(woff2);
  font-family: "Red-Hat-Display-SemiBold";
  font-display: swap;
}
@font-face{
  src: url(assets/fonts/RedHatDisplay-Bold.woff2) format(woff2);
  font-family: "Red-Hat-Display-Bold";
  font-display: swap;
}
@font-face{
  src: url(assets/fonts/RedHatDisplay-ExtraBold.woff2) format(woff2);
  font-family: "Red-Hat-Display-Extrabold";
  font-display: swap;
}



@font-face{
  src: url(assets/fonts/Poppins-Light.woff2) format(woff2);
  font-family: "Poppins-light";
  font-display: swap;
}
@font-face{
  src: url(assets/fonts/Poppins-Regular.woff2) format(woff2);
  font-family: "Poppins-Regular";
  font-display: swap;
}
@font-face{
  src: url(assets/fonts/Poppins-Medium.woff2) format(woff2);
  font-family: "Poppins-Medium";
  font-display: swap;
}
@font-face{
  src: url(assets/fonts/Poppins-SemiBold.woff2) format(woff2);
  font-family: "Poppins-Semibold";
  font-display: swap;
}
@font-face{
  src: url(assets/fonts/Poppins-Bold.woff2) format(woff2);
  font-family: "Poppins-Bold";
  font-display: swap;
}


html {
  scroll-behavior: smooth;
  -webkit-tap-highlight-color: transparent;
}

::-moz-selection { /* Code for Firefox */
  color: #fff;
  background: #00ACEB;
}

::selection {
  color: #fff;
  background: #00ACEB;
}

::-webkit-scrollbar{
width: 12px;
background: rgb(206, 204, 204);
}

::-webkit-scrollbar-thumb{
background:#6b6a6a;

}

::-webkit-scrollbar-thumb:hover{
background:#4b4b4b;
cursor: pointer;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins-Regular", sans-serif;
  overflow-x: hidden;
  color: #000;
  background-color: white !important;
}



button:focus {
  outline: none;
}

a:active,
a:hover,
a:focus,
a:visited {
  text-decoration: none;
}
input{
  outline: none;
  border-radius: 3px;
  border: 2px solid transparent;
}
input[type="file"]:focus{
  border: 2px solid transparent;
}
select:focus {
  border-color: #007D9B !important;
}
textarea{
  outline: none;
  border-radius: 3px;
  border: 2px solid transparent;
}
input:focus{
  border: 2px solid #007D9B;
}
textarea:focus{
  border: 2px solid #007D9B;
}

li{
  list-style: none !important;
}

/* Header */
.button-30 {
  align-items: center;
  appearance: none;
  background-color: #00ACEB;
  border-radius: 4px;
  border-width: 0;
  box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px,rgba(45, 35, 66, 0.3) 0 7px 13px -3px,#007098 0 -3px 0 inset;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-flex;
  height: 35px;
  justify-content: center;
  line-height: 1;
  list-style: none;
  overflow: hidden;
  padding-left: 16px;
  padding-right: 16px;
  position: relative;
  text-align: left;
  text-decoration: none;
  transition: box-shadow .15s,transform .15s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  will-change: box-shadow,transform;
}

.button-30:focus {
  box-shadow: #D6D6E7 0 0 0 1.5px inset, rgba(45, 35, 66, 0.4) 0 2px 4px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #D6D6E7 0 -3px 0 inset;
}

.button-30:hover {
  box-shadow: rgba(45, 35, 66, 0.4) 0 4px 8px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #007098 0 -3px 0 inset;
  transform: translateY(-1px);
}

.button-30:active {
  box-shadow: #D6D6E7 0 3px 7px inset;
  transform: translateY(2px);
}


/* Footer */
.underline-hover-effect {
  padding-bottom: 0.10rem; /* defines the space between text and underline */
  position: relative;
}

.underline-hover-effect::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 2px;
  background-color: #00A3C8;
  transition: width 0.25s ease-out;
}
.underline-hover-effect:hover::before {
  width: 100%;
}

.underline-hover-effect2 {
  padding-bottom: 0.10rem; /* defines the space between text and underline */
  position: relative;
}

.underline-hover-effect2::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 1px;
  background-color: #00A3C8;
  transition: width 0.25s ease-out;
}
.underline-hover-effect2:hover::before {
  width: 100%;
}



/* Banner slide */
.arrow1{
  visibility: hidden;
  transition: visibility .3s ease-in-out;
}
.bannerslider:hover .arrow1{
  visibility: visible;
  
}
.arrow1{
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  z-index: 9;
  cursor: pointer;
  font-size: 20px;
  color: #fff;
}

.arrow-right1{
  right: 20px;
}
.arrow-left1{
  left: 20px;
}

.bannerimages{
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  -webkit-filter: blur(1px);
  filter: blur(1px);
  filter: brightness(40%);
}



/* Testimonial */

.arrow2{
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  z-index: 9;
  cursor: pointer;
  font-size: 25px;
  color: #fff;
}

.arrow-right2{
  right: 20px;
}
.arrow-left2{
  left: 20px;
}

@media only screen and (max-width: 600px){
  .arrow2{
    top: 100%;
  }
}

.testimonial-slide .slick-dots button:before{
  color: #00ACEB !important;
}

/* coming soon */

.comming-wrapper h1 {
  font-size: 50px;
  font-family: 'Poppins', sans-serif;
  margin-bottom: 0;
  line-height: 1;
  font-weight: 700;
}

.dot {
  color: #4FEBFE;

}

/* coming soon */


/* Job */
  .job-modal-content::-webkit-scrollbar{
  width: 5px;
  background: rgb(146, 146, 146);
  }
  .job-modal-content::-webkit-scrollbar-thumb{
  background:#000000;
  
  }
  
  .job-modal-content::-webkit-scrollbar-thumb:hover{
  background:#676767;
  cursor: pointer;
  }
/* Job */

/* top menu */
.adminnavbar .active{
  color: #00ACEB;
}
.adminnavbar li{
  transition: all .3s ease-in;
 
}
.adminnavbar li:hover{
  color: #00ACEB;
}
/* top menu */

/* Admin */
/* Admin Navbar */

/* Admin  */


/* Loader */

.loader {
  position: relative;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-image: url('./assets/3seasonemp.svg');
  background-size: cover;
  background-position: center;
}

.loader:before , .loader:after{
  content: '';
  border-radius: 50%;
  position: absolute;
  inset: 0;
  box-shadow: 0 0 10px 2px #00aceb68 inset;
  /* box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.3) inset; */
}
.loader:after {
  box-shadow: 0 3px 0 #00ACEB inset;
  animation: rotate 1s linear infinite;
 
}

@keyframes rotate {
  0% {  transform: rotate(0)}
  100% { transform: rotate(360deg)}
}
/* Loader */
/* LoadingScreen.css */
.loading-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff; /* Set your desired background color */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Ensure it's above other elements */
  opacity: 1; /* Initially visible */
  transition: opacity 0.5s ease-in-out; /* Smooth transition */
  pointer-events: auto; /* Allow interactions while loading */
}

.loading-screen.show {
  opacity: 0; /* Hide when loading is complete */
  pointer-events: none; /* Disable interactions when hidden */
  transition-delay: 0.5s; /* Delay transition */
}

.spinner {
  /* Style your loading spinner or animation */
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Your styles for main content */
.main-content {
  opacity: 0; /* Initially hidden */
  transition: opacity 0.5s ease-in-out; /* Smooth transition */
}

.main-content.show {
  opacity: 1; /* Show when loading is complete */
  transition-delay: 0.5s; /* Delay transition */
}






.companyslide .slick-list {
  margin: 0 -15px !important;
}

.companyslide .slick-slide > div {
  padding: 0 15px !important;
}